import React, { Fragment, useEffect, useMemo, useReducer, useRef, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { UseAPI } from "../../../services/UseAPI";
import swal from "sweetalert";
import TanahTable from "./TanahTable";
import { Button, Dropdown, Modal, Table } from "react-bootstrap";
import FormElementsVertical from "./FormElementsVerical";
import Select from "react-select";
import { KOLOM_TANAH } from "./Columns";
import { Link, useHistory } from "react-router-dom";
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import './filtering.css';
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdjust, faFilter, faLayerGroup, faPaperPlane, faPlus } from "@fortawesome/free-solid-svg-icons";
import { ExportData } from "../../../services/Utils";

const init =  false;

const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'contentModal'	:
			return { ...state, contentModal: !state.contentModal}
		case 'modalCentered'	:
			return { ...state, modalCentered: !state.modalCentered}
		case 'modalWithTooltip'	:
			return { ...state, modalWithTooltip: !state.modalWithTooltip}		
		case 'gridInsideModal'	:
			return { ...state, gridInsideModal: !state.gridInsideModal}
		case 'largeModal'	:
			return { ...state, largeModal: !state.largeModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
		default:	
			return state;
	}	
}

const Tanah = () => {
  const [field, setField] = useState([])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);  

  const [optionData,setOptionData] = useState([]);
  const [whereCondition,setWhereCondition] = useState('');

  const [jenisSertifikat, setJenisSertifikat] = useState({});
  const [statusTanah, setStatusTanah] = useState({});
  const [caraPerolehan, setCaraPerolehan] = useState({});
  const [dispatch] = useReducer(reducer, init);

  
  const activePag = useRef(0);
  const sort = 5;

  const [sourcedata, setSourceData] = useState([]);
  const columns = useMemo( () => KOLOM_TANAH, [] )
  let history = useHistory();
  const handleRowAction = (row) => {
    console.log(row);
    history.push("/tanah/edit/"+row.id);
  }
  const handleRowDocument = (row) => {
    console.log(row);
    history.push("/tanah/dokumen/"+row.id);
  }

  const handleRowActionDelete = (row) => {
    Swal.fire({
    title: "Anda yakin ?",
    text: "Data yang sudah dihapus tidak dapat dikembalikan",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Ya"
    }).then((result) => {
      if (result.isConfirmed) {
        UseAPI(`tanah/${row.id}`,{},'DELETE').then((resp) => {
          if (resp.status === 'ok') { 
          setSourceData(resp.data);
          Swal.fire({
            title: resp.pesan,
            text: "Berhasil dihapus",
            icon: "success"
          });

          } else {
          Swal.fire({
            title: resp.pesan,
            text: "Gagal dihapus",
            icon: "danger"
          });
          }
        })	
      }
    });	
    console.log(row);
  }  
  useEffect(()=>{

    let tanah = [];
    UseAPI('tanah',{},'GET').then((resp)=>{
      console.log("tanah",resp);
      setSourceData(resp);
    })       

    UseAPI('jenis_sertifikat',{},'GET').then((resp)=>{
      let jenis_sertifikat = [];
      resp.map((item)=>{
        jenis_sertifikat.push({label:item.nama_jenis_sertifikat,value:item.id,name:'jenis_sertifikat_id'})
      })
      setJenisSertifikat(jenis_sertifikat)
    });  
    UseAPI('status_tanah',{},'GET').then((resp)=>{
      let status_tanah = [];
      resp.map((item)=>{
        status_tanah.push({label:item.nama_status_tanah,value:item.id,name:'status_tanah_id'})
      })
      setStatusTanah(status_tanah);
    });  
    UseAPI('cara_perolehan',{},'GET').then((resp)=>{
      let cara_perolehan = [];
      resp.map((item)=>{
        cara_perolehan.push({label:item.nama_cara_perolehan,value:item.id,name:'cara_perolehan_id'})
      })
      setCaraPerolehan(cara_perolehan);
    });  

  },[])

  const handleDelete = (id) => {
    console.log("hapus",id);
    UseAPI(`tanah/${id}`,'','DELETE').then((resp) => {
      if (resp.status === 'ok') {
        setSourceData(resp.data);
        swal(resp.pesan, {
          icon: "success",
        });    
        // window.location.reload();
      } else {
        swal(resp.pesan, {
          icon: "danger",
        });    
      }
    })
  }

  const dataOnModal = () =>{
    let dataInput = [];
    Object.key(field).map((key,item)=>{
      return (
        <p>test</p>
      )
    })
  }

  const optionChange = (e) => {
    console.log(e);
    setOptionData(values => ({...values, [e.name]: e.value}))
  }

  const handleCari = () => {
    let queryParam = [];
    console.log(optionData);
    Object.entries(optionData).map(([key,val])=>{
      console.log(val);
      if (parseInt(val) > 0) {
        queryParam.push(`${key}=${val}`)
      }
    })
    setWhereCondition(queryParam.join("&"))
    // console.log(queryParam.join("&"));
    if (whereCondition !== '') {
      UseAPI('tanah/?from=0&'+queryParam.join("&"),{},'GET').then(async (resp)=>{
        console.log("tanah_cari",resp);
        setSourceData(resp.data);
      });  
    } else {
      UseAPI('tanah/?from=0&',{},'GET').then(async (resp)=>{
        console.log("tanah_cari",resp);
        setSourceData(resp.data);
      });  

    }
    dispatch({type:'basicModal'})


  }

  const onPagination = (i) =>{
    console.log("i",i);
    let where = whereCondition ??= ''
    activePag.current = i; 
    if (i <= 0) {
      activePag.current = 0;
    } else if (data.length < window.dataLimit) {
      activePag.current = (i);
    }
    console.log("activePage",activePag.current);
    console.log("datalength",data.length);
    if (where === '') {
      UseAPI('tanah/?from='+(activePag.current*window.dataLimit),{},'GET').then((resp)=>{
        console.log("tanah_cari",resp);
        setSourceData(resp.data);
      });  
    } else {
      UseAPI('tanah/?from='+(activePag.current*window.dataLimit)+'&'+where,{},'GET').then((resp)=>{
        console.log("tanah_cari",resp);
        setSourceData(resp.data);
      });  

    }
    // console.log(activePag.current);
  }
  const handleReset = () =>{
    setWhereCondition('')
    UseAPI('tanah/?from='+(activePag.current*window.dataLimit),{},'GET').then((resp)=>{
      console.log("tanah_cari",resp);
      setSourceData(resp.data);
    });  

    dispatch({type:'basicModal'})
   
  }

  const data = useMemo( () => sourcedata, [sourcedata] )
  const tableInstance = useTable({
    columns,		
    data,	
    initialState : {pageIndex : 0},
    handleRowAction,
    handleRowActionDelete,
    handleRowDocument,
  }, useFilters, useGlobalFilter, usePagination)
  const { 
    getTableProps, 
    getTableBodyProps, 
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance
  const {globalFilter, pageIndex} = state  

  return (
    <Fragment>
			<Modal className="fade" show={state.basicModal} onHide={()=>dispatch({type:'basicModal'})}>
				<Modal.Header>
					<Modal.Title>Filter</Modal.Title>
					<Button variant="" className="btn-close" onClick={() => dispatch({type:'basicModal'})}></Button>
				</Modal.Header>
				<Modal.Body>
            <div className="mb-3 row">
                <label className="col-form-label">{'Cara Perolehan'}</label>
                <Select
                    // value={bidang[inputs.bidang_id]}
                    onChange={optionChange}
                    options={caraPerolehan}
                    name={'cara_perolehan_id'}
                    id={'cara_perolehan_id'}
                />                                         
            </div>    
            <div className="mb-3 row">
                <label className="col-form-label">{'Jenis Sertifikat'}</label>
                <Select
                    // value={bidang[inputs.bidang_id]}
                    onChange={optionChange}
                    options={jenisSertifikat}
                    name={'jenis_sertifikat_id'}
                    id={'jenis_sertifikat_id'}
                />                                         
            </div>    
            <div className="mb-3 row">
                <label className="col-form-label">{'Status Tanah'}</label>
                <Select
                    // value={bidang[inputs.bidang_id]}
                    onChange={optionChange}
                    options={statusTanah}
                    name={'status_tanah_id'}
                    id={'status_tanah_id'}
                />                                         
            </div>    



          {/* {
            Object.entries(field).map(([key,item])=>{
              if (item.tag === 'select') {
                return (
                  <FormElementsVertical
                    key={key}
                    type={item.tag}
                    label={item.label}
                    name={key}
                    datasource={item}
                    onChange={optionChange}
                  />

                )
              }
            })
          } */}
        </Modal.Body>
				<Modal.Footer>
					<Button  variant="warning light" onClick={() => handleReset()}>Reset Filter</Button>
					<Button  variant="danger light" onClick={() => dispatch({type:'basicModal'})}>Batal</Button>
					<Button variant="primary" onClick={()=>handleCari()}>Cari</Button>
				</Modal.Footer>
			</Modal>
      <PageTitle
        activeMenu="Daftar"
        motherMenu="Tanah"
        pageContent="Tanah"
      />

        <div className="card">
          <div className="card-header">
          <h4 className="card-title">Tanah</h4>
            <div className="d-flex align-items-center justify-content-between">
              <Dropdown className="dropdown">
                <Dropdown.Toggle as="div" className="btn-link i-false">
                <FontAwesomeIcon icon={faLayerGroup} className="fa-2x" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                  <Dropdown.Item as={Link} to={'/tanah/tambah'} className="dropdown-item"> Baru</Dropdown.Item>
                  <Dropdown.Item onClick={()=> {}} className="dropdown-item"> Export</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>            

          </div>
          <div className="card-body">
            <div className="table-responsive">

                    <Table {...getTableProps()} striped bordered hover size='sm'>
                      <thead>
                        {headerGroups.map(headerGroup => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                              <th {...column.getHeaderProps()}>
                                {column.render('Header')}
                                {column.canFilter ? column.render('Filter') : null}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead> 
                      <tbody {...getTableBodyProps()} className="" >
                      
                        {page.map((row) => {
                          prepareRow(row)
                          return(
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()} style={{padding:5}}> {cell.render('Cell')} </td>
                              })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>

                    <div className="d-flex justify-content-between">
                      <span>
                        Page{' '}
                        <strong>
                          {pageIndex + 1} of {pageOptions.length}
                        </strong>{''}
                      </span>
                      <span className="table-index">
                        Go to page : {' '}
                        <input type="number" 
                          className="ml-2"
                          defaultValue={pageIndex + 1} 
                          onChange = {e => { 
                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
                            gotoPage(pageNumber)
                          } } 
                        />
                      </span>
                    </div>
                    <div className="text-center mb-3">	
                      <div className="filter-pagination  mt-3">
                        <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                        
                        <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                          Previous
                        </button>
                        <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                          Next
                        </button>
                        <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                      </div>
                    </div>

                {/* <TanahTable
                  title="Daftar"
                  data={data}
                  field={field}
                  link={'tanah'}
                  onDelete={handleDelete}
                  showFilterModal={dispatch}
                  where={whereCondition}
                  onPagination={onPagination}
                  activePag={activePag}
                  sort={sort}
                /> */}
            </div>
          </div>
        </div>

    </Fragment>
  );
};

export default Tanah;
